import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { LookupService } from '../shared/api/lookup.service';
import { Eventa } from '../shared/models/eventa.model';
import { Material } from '../shared/models/material';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.css']
})
export class MaterialsComponent implements OnInit {

  counts: number[]=[];
  countsMaterials: number[]=[];
  materialsLoaded:Boolean=false;
  materials:Material[]=[];
  materialsImagesPath:String='';
  currentHeader:String='';
  loopIndexer : number=0;
  loopTypeIndexer:number=0;
  separatorFlag:boolean=false;

  loopTypeCounterIndexer:number=0;
  loopTypeArrayIndexer:number=0;
  constructor(private lookupService:LookupService,private spinner: NgxSpinnerService,private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.loadMaterials()
  }
  increment()
  {
    this.loopIndexer++;
    this.separatorFlag=false;
  }

  counter(i: number) {
    return new Array(i);
}
ngAfterContentChecked() {

  this.cdref.detectChanges();

}
incrementCounter()
{
  this.loopTypeIndexer+=this.counts[this.loopTypeCounterIndexer];
  this.loopTypeCounterIndexer++;
}
checkHeader()
{
  if(true){//this.materials[this.loopIndexer]){
  console.log('header',this.loopIndexer,this.materials[this.loopIndexer].materialtype_name,this.currentHeader)
  if(this.materials[this.loopIndexer] && this.materials[this.loopIndexer].materialtype_name!=this.currentHeader)
  {
    this.currentHeader=this.materials[this.loopIndexer].materialtype_name;
    return true;
  }
}
  return false;
}

checkIndex()
{
  if(this.loopTypeIndexer==this.countsMaterials[this.loopTypeArrayIndexer])
  {
    this.loopTypeIndexer=0;
    this.loopTypeArrayIndexer++;
    return true;
  }
  return false;
}
  loadMaterials()
  {

    this.spinner.show();
    this.lookupService.getMaterialsList().subscribe((data: any) => {
    this.materials=data.results;
    let currentCounter=0;
    let currentFamilyCounter=0;
     
    if(this.materials.length)
    {  
      let currentFamily=this.materials[0].materialfamilyid;
      let currentType=this.materials[0].materialtype_name;
      for(let i=0;i<this.materials.length;i++)
      {
      
      if(currentFamily!=this.materials[i].materialfamilyid)
      {
          this.counts.push(currentCounter);
          currentCounter=0;
          currentFamily=this.materials[i].materialfamilyid;
      }
      /////////////////////////
      if(currentType!=this.materials[i].materialtype_name)
      {
        this.countsMaterials.push(currentFamilyCounter);
        currentFamilyCounter=0;
        currentType=this.materials[i].materialtype_name;
      }
      /////////////////////////
      currentFamilyCounter++;
      currentCounter++;
      }
   }
   this.counts.push(currentCounter);
   this.countsMaterials.push(currentFamilyCounter);
    this.materialsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+data.stats.imagepath_material;
    this.spinner.hide();
    console.log(this.countsMaterials);
    console.log(this.counts);
    console.log('--------------------------------------------------');
    this.materialsLoaded=true;
  },response => {
    this.spinner.hide();
    console.error(response);
  });
  }

}
