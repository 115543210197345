import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';



import { AboutComponent } from './about/about.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContactComponent } from './contact/contact.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { DesignersComponent } from './designers/designers.component';
import { EventsComponent } from './events/events.component';
import { MaterialsComponent } from './materials/materials.component';




const routes: Routes = [
  // otherwise redirect to home
  //{ path: '**', redirectTo: '' },
  { path: 'contact', component: ContactComponent,data: {title: 'Contact' , animation: 'contact' } },
  { path: 'products', component: ProductsComponent,data: {title: 'Products' , animation: 'Products' } },
  { path: 'about', component: AboutComponent,data: {title: 'About' , animation: 'transactions' } },
  { path: 'designers', component: DesignersComponent,data: {title: 'Designers' , animation: 'transactions' } },
  { path: 'home',  component: HomeComponent, data: {title: 'Home' , animation: 'home' } } ,
  { path: 'product/:id', component: ProductDetailComponent, data: { animation: 'product-detail' } },
  { path: 'events', component: EventsComponent,data: {title: 'Events' , animation: 'Events' } },
  { path: 'materials', component: MaterialsComponent,data: {title: 'Materials' , animation: 'Materials' } },
  { path: 'events/:id', component: EventDetailComponent, data: { animation: 'event' } },
  { path: '', redirectTo: 'home', pathMatch: 'full',  data: {title: 'Home' , animation: 'home' } } ,
  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];


//export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
