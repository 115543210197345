import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ignoreElements } from 'rxjs/operators';
import { LookupService } from '../shared/api/lookup.service';
import { NotifyService } from '../shared/services/notify.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  createForm!: FormGroup;
  constructor(
    private spinner: NgxSpinnerService,private lookUpService:LookupService,private notifyService:NotifyService) { }

  ngOnInit(): void {
    this.createForm = new FormGroup({
      name:new FormControl(null, [Validators.required]),
      mobile:new FormControl(null),
      mail: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      message : new FormControl(null, [Validators.required]),
      
     //  isActive:new FormControl(null,[Validators.required])
    });
    
      this.createForm.setValue({
        name:null,
        mail:null,
        mobile:null,
        message:null
        // isActive : this.comb.isActive,
       });
    
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
//Helper functions
formField(fieldName: string) {
  return this.createForm.get(fieldName);
}
public findInvalidControls() {
  const invalid = [];
  const controls = this.createForm.controls;
  for (const name in controls) {
      if (controls[name].invalid) {
          invalid.push(name);
      }
  }
  return invalid;
}

////////////////////////////////////////////////////////////////////////////////////////////
//Submit Form functions
onSubmitForm(formRef: any) {
  let newEntry = formRef.value;
  this.spinner.show();
  //Adjusting data
  ////////////////////////////////////////////Edit Case////////////////////////////////////////
  newEntry.addedby='contact_form';
  console.log(newEntry);
  this.lookUpService.sendEmail(newEntry).subscribe((data: any) => {
        this.spinner.hide();

        this.notifyService.onSuccess('Email Sent','Success');
        this.createForm.reset();   
      },response => {
          this.spinner.hide();
          console.error(response)
       
          let errorString:any=response?.status;
          if(errorString==201)// || errorString.includes('Accepted'))
          {
            this.notifyService.onSuccess('Email Sent','Success');
            this.createForm.reset();
          }
          else
          {
           console.error(response);
           this.notifyService.onError('Something went wrong , please try again later','Error');
          }
        });
      
  }

  
  clearForm()
  {
    this.createForm.setValue({
      name:null,
      email:null,
      phone:null,
      message:null
      // isActive : this.comb.isActive,
     });
  }
}
