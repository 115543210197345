import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LookupService } from './shared/api/lookup.service';
import { AppComponent } from './app.component';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HttpRequestsService } from './shared/services/http-request.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DesignersComponent } from './designers/designers.component';
import { EventsComponent } from './events/events.component';
import { ContactComponent } from './contact/contact.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NotifyService } from './shared/services/notify.service';
import { ModalModule } from './services/_modal';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { MaterialsComponent } from './materials/materials.component';
@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,
    ProductsComponent,
    HomeComponent,
    MaterialsComponent,
    ContactComponent,
    ProductDetailComponent,
    EventDetailComponent,
    DesignersComponent,
    EventsComponent,
    MaterialsComponent,
     
  ],
  
  imports: [
    BrowserModule,FormsModule,
    AppRoutingModule, 
    ModalModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    AngularFullpageModule,
    SnotifyModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [HttpRequestsService,LookupService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    NotifyService,
    { provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
