import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { LookupService } from '../shared/api/lookup.service';
import { Eventa } from '../shared/models/eventa.model';
import { Product } from '../shared/models/product.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title = 'Aurea';
  config: any;
  fullpage_api: any;
  sectionNumber = 1;
  hold = false;
  name = 'Angular ';
  popularProducts: Product[] = []; 
  events:Eventa[]=[];
  eventsImagesPath:string='';
  productsImagesPath:string='';
  constructor( public lookupService:LookupService,public router:Router) {
    this.config = {
      licenseKey: 'gplv3-license',
      sectionsColor: ['#7BAABE', 'whitesmoke', '#7BAABE', 'whitesmoke', '#7BAABE'],
      anchors: ['p1', 'p2', 'p3', 'p4', 'p5'],
      navigation: true,
    };
    this.applyScripts();
  this.loadEvents();
   }

  ngOnInit(): void {
    
  }
  getRef(fullPageRef:any) {
    this.fullpage_api = fullPageRef;
  }
  loadEvents()
  {
    //this.spinner.show();
    this.lookupService.getEvents(1,3).subscribe((data: any) => {
      console.log(data);
    this.events=data.results;
    this.eventsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+data.stats.imagepath_event;
    this.loadProducts();
  },response => {});
  }

  loadProducts()
  {
    //this.spinner.show();
    this.lookupService.getRandomProducts().subscribe((data: any) => {
      console.log(data);
    this.popularProducts=data.results;
    this.productsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+'/uploads/productpic/';
  },response => {});
  }

                ////////////////////////////////////////////////////////////////////////////////////////////
                applyScripts()
                {
                  //if (document.getElementById('main_gallery_js') !=null) {document.getElementById('main_gallery_js').remove();}
                  const node = document.createElement('script');
                  node.src = 'assets/js/main.js';
                  node.type = 'text/javascript';
                  node.async = false;
                  node.id = 'main_js';
                  node.charset = 'utf-8';
                  document.getElementsByTagName('head')[0].appendChild(node);
                  // const node2 = document.createElement('script');
                  // node2.src = 'assets/js/home.js';
                  // node2.type = 'text/javascript';
                  // node2.async = false;
                  // node2.id = 'home_js';
                  // node2.charset = 'utf-8';
                  // document.getElementsByTagName('head')[0].appendChild(node2);
                }

                // changeMouseWheel(e: { deltaY: number; preventDefault: () => void; }) {
                //   const sectionCount = document.querySelectorAll('.panel').length;
                //   const windowHeight = window.innerHeight;
                //   if (e.deltaY < 0 && this.sectionNumber > 1) {
                //     if (this.hold === false) {
                //       this.hold = true;
                //       this.sectionNumber -= 1;
                //       const element = document.getElementById(`el${this.sectionNumber}`);
                //       this.scroll(element.offsetTop, 0);
                //       setTimeout(() => {
                //         this.hold = false;
                //       }, 500);
                //       e.preventDefault();
                //     }
                //   }
                //   if (e.deltaY > 0 && this.sectionNumber < sectionCount) {
                //     const currentElement = document.getElementById(`el${this.sectionNumber}`);
                //     if (((currentElement.offsetTop + currentElement.offsetHeight) - windowHeight) <= document.documentElement.scrollTop) {
                //       if (this.hold === false) {
                //         this.hold = true;
                //         this.sectionNumber += 1;
                //         console.log(`#el${this.sectionNumber}`);
                //         const nextElement = document.getElementById(`el${this.sectionNumber}`);
                //         this.scroll(nextElement.offsetTop, 0);
                //         setTimeout(() => {
                //           this.hold = false;
                //         }, 500);
                //         e.preventDefault();
                //       }
                //     }
                //   }
                // }
              
              
                // scroll(topData: number, leftData: number) {
                //   window.scrollTo({
                //     top: topData,
                //     left: leftData,
                //     behavior: 'smooth'
                //   });
                // }
              
            
  
}
