import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private router: Router){}

  ngOnInit() {
    this.setUpScroll();
    this.setUpAnalytics();
  }

  setUpScroll(){
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
      });
  }

  setUpAnalytics() {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log(event.urlAfterRedirects);
          gtag('config', 'UA-244980592-1',{page_path: event.urlAfterRedirects});
      }      
      });
  }

}


