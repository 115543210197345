import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ModalService } from '../services/_modal/modal.service';
import { LookupService } from '../shared/api/lookup.service';
import { Category } from '../shared/models/category.model';
import { Product } from '../shared/models/product.model';
import { Room } from '../shared/models/room.model';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
    
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  createForm!: FormGroup;
  categoriesList: Category[] = [];
  roomsList: Room[] = [];
  productsList: Product[] = [];
  @ViewChild('categorySelected') categorySelected: ElementRef | undefined;
  @ViewChild('roomSelected') roomSelected: ElementRef | undefined;
  storesData: any;
  firstRoom:Room | undefined;
  firstCategory:Category | undefined;
  categoriesLoaded=false;
  roomsLoaded=false;
  productsLoaded=false;
  selectedCategoryValue:number =-1;
  selectedRoomValue:number =-1;
  productsImagesPath: string='';
  roomID=-1;
  categoryID=-1;
  validatingForm: FormGroup =new FormGroup({});
  constructor(private lookupService:LookupService,private lookUpService:LookupService,private modalService:ModalService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    
    this.loadRooms();
    this.loadCategories();
    this.loadProducts(-1,-1);
  //  this.showActions =true;
  //  this.dataLoaded = true;
  this.createForm = new FormGroup({
    name:new FormControl(null, [Validators.required]),
    phone:new FormControl(null),
    mail: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
     //  isActive:new FormControl(null,[Validators.required])
  });
  
    this.createForm.setValue({
      name:null,
      mail:null,
      phone:null,
      // isActive : this.comb.isActive,
     });
  }
//Helper functions
formField(fieldName: string) {
  return this.createForm.get(fieldName);
}
  loadRooms()
  {
    this.lookupService.getRoomsList().subscribe( (data: any) => {
      console.log(data);
      this.roomsList = data.results?data.results:data;
      this.firstRoom=new Room;
      this.firstRoom.id=-1;
      this.firstRoom.name='--Select Room--';
      this.roomsList.unshift(this.firstRoom);
      this.roomsLoaded=true;
  });
}




openModal(id: string) {
  this.modalService.open(id);
}

closeModal(id: string) {
  this.modalService.close(id);
}
  OnRoomChange(event:any)
  {
    this.roomID=event;
    this.loadProducts(this.categoryID,this.roomID);
  }
  
  OnCategoryChange(event:any)
  {
    this.categoryID=event;
    this.loadProducts(this.categoryID,this.roomID);
  }

  loadCategories()
  {
    this.lookupService.getCategoriesList().subscribe( (data: any) => {
      this.categoriesList = data.results?data.results:data;
      this.firstCategory=new Category;
      this.firstCategory.id=-1;
      this.firstCategory.name='--Select Category--';
      this.categoriesList.unshift(this.firstCategory);
      this.categoriesLoaded=true;
  });
 } 
 loadProducts(categoryid:number,roomid:number)
 {
  this.spinner.show();
  console.log(roomid);
   this.lookupService.getProducts(categoryid,roomid).subscribe( (data: any) => {
    this.productsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+data.stats.imagepath_productpic;
     this.productsList = data.results?data.results:data;
     this.productsLoaded=true;
     this.spinner.hide();
 },response => {
  console.error(response);
  this.spinner.hide();
});
} 


public findInvalidControls() {
  const invalid = [];
  const controls = this.createForm.controls;
  for (const name in controls) {
      if (controls[name].invalid) {
          invalid.push(name);
      }
  }
  return invalid;
}

////////////////////////////////////////////////////////////////////////////////////////////
//Submit Form functions
onSubmitForm(formRef: any) {
  let newEntry = formRef.value;
  this.spinner.show();
  //Adjusting data
  ////////////////////////////////////////////Edit Case////////////////////////////////////////
  newEntry.addedby='contact_form';
  console.log(newEntry);
  this.lookUpService.fillForm(newEntry).subscribe((data: any) => {
        this.spinner.hide();
        this.downloadPdf();
        this.createForm.reset();
        
        this.closeModal('custom-modal-1');   
      },(response: String) => {
          this.spinner.hide();
          let errorString:String=response;
          if(errorString.includes('20'))// || errorString.includes('Accepted'))
          {
            this.downloadPdf();
            this.createForm.reset();
            this.closeModal('custom-modal-1');
          }
          else
          {
            this.closeModal('custom-modal-1');
           console.error(response);
          }
        });
      
  }

  downloadPdf() {
    const pdfUrl = './assets/Aurea.pdf';
    const pdfName = 'Aurea_Catalogue';
    FileSaver.saveAs(pdfUrl, pdfName);
  }
  
  clearForm()
  {
    this.createForm.setValue({
      name:null,
      email:null,
      phone:null,
      message:null
      // isActive : this.comb.isActive,
     });
  }

}
