import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { LookupService } from '../shared/api/lookup.service';
import { Eventa } from '../shared/models/eventa.model';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor(private lookupService:LookupService,private spinner: NgxSpinnerService) { }
  events:Eventa[]=[];
  ////////////////////
  firstFlag:boolean=true;
  lastFlag:boolean=true;
  currentPage:number=1;
  pageSize:number=9;
  totalItems:number=0;
  itemsPages:number[]=[];
  eventsImagesPath:string='';
  ngOnInit(): void {
    this.loadEvents(1,true);
  }
  loadEvents(pageNumber:number,resetPaging:boolean)
  {

    this.currentPage=pageNumber;
    this.spinner.show();
    this.lookupService.getEvents(pageNumber,this.pageSize).subscribe((data: any) => {
    this.events=data.results;
    this.totalItems=data.stats.count;
    this.constructPaging(pageNumber,resetPaging);
    this.eventsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+data.stats.imagepath_event;
    this.spinner.hide();
  },response => {
    this.spinner.hide();
    console.error(response);
  });
  }
  
  constructPaging(pageNumber:number,resetPaging:boolean)
  {
    this.firstFlag=(pageNumber==1);
    this.lastFlag=(pageNumber*this.pageSize>=this.totalItems);
    if(resetPaging)
    {
      for(let i=0;i<(this.totalItems/this.pageSize);i++)this.itemsPages.push(i+1);
    }
  }
}
