import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment.prod';
import { LookupService } from '../shared/api/lookup.service';
import { Product } from '../shared/models/product.model';
import { ProductPic } from '../shared/models/productPic.model';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  productLoaded=false;
  product:Product=new Product;
  productsImagesPath:string='';
  filterParam : number | undefined;
  constructor(private lookupService:LookupService,private route: ActivatedRoute,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.filterParam = Number(this.route.snapshot.paramMap.get('id'));
    this.loadProduct(this.filterParam);
    
  }

  getURL(input:String)
  {
    console.log(this.productsImagesPath+input)
    return this.productsImagesPath+input;
  }

  loadProductRooms(id:number)
  {
    this.lookupService.getProductRooms(id).subscribe( (data: any) => {
      
      this.product.rooms = data.results?data.results:data;
      console.log(this.product.rooms);
      
  },response => {
    console.error(response);
  });
}


  loadProduct(id:number)
  {
  this.spinner.show();
    this.lookupService.getProduct(id).subscribe( (data: any) => {
     this.productsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+'/uploads/productpic/';
      this.product = data.results?data.results:data;
      this.productLoaded=true;
      this.spinner.hide();
      this.loadProductRooms(id);
      this.loadProductImages(id);
      
  },response => {
    console.error(response);
    this.spinner.hide();
  });
 } 

 loadProductImages(id:number)
 {
   this.lookupService.getProductPics(id).subscribe( (data: any) => {
    
    //this.productsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+'/uploads/productpic/';
    this.product.pics = new Array();
    this.product.secondaryPics = new Array();
     for(let i=0;i<data.results.length;i++)
     {
       if(data.results[i].pictype==3)
       this.product.pics.push(data.results[i]);
       if(data.results[i].pictype==2)
       this.product.secondaryPics.push(data.results[i]);
       if(data.results[i].pictype==4)
       this.product.productpic_extraimage=data.results[i].image;
       
     }
     
     console.log(data.results)
     console.log(this.product.pics)
     this.applyScripts();
     console.log(this.spinner);
     //this.spinner.hide();
 },response => {
  console.error(response);
});
} 
                ////////////////////////////////////////////////////////////////////////////////////////////
                applyScripts()
                {
                  //if (document.getElementById('main_gallery_js') !=null) {document.getElementById('main_gallery_js').remove();}
                  const node = document.createElement('script');
                  node.src = 'assets/js/main.js';
                  node.type = 'text/javascript';
                  node.async = false;
                  node.id = 'main_js';
                  node.charset = 'utf-8';
                  document.getElementsByTagName('head')[0].appendChild(node);
                }

}
