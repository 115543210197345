import { ProductPic } from "./productPic.model"
import { Room } from "./room.model"

export class Product {
  id: number
  name: string
  desc: string
  productpic_image: string
  isfeatured: number
  isactive: number
  categoryid: number
  category_name:String
  productpic_extraimage:String
  rooms : Room[] | undefined
  pics: ProductPic[] | undefined
  secondaryPics: ProductPic[] | undefined
  constructor()
  {
    this.id=0;
    this.name='';
    this.desc='';
    this.productpic_image='';
    this.isfeatured=0;
    this.isactive=0;
    this.category_name='';
    this.productpic_extraimage='';
    this.categoryid=0;
  }
}