import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { LookupService } from '../shared/api/lookup.service';
import { Eventa } from '../shared/models/eventa.model';
@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  constructor(private lookupService:LookupService,private route: ActivatedRoute,private spinner: NgxSpinnerService) { }
  item:Eventa=new Eventa;
  eventsImagesPath:string='';
  filterParam : number | undefined;
  ngOnInit(): void {
    this.filterParam = Number(this.route.snapshot.paramMap.get('id'));
    this.loadEvent(this.filterParam);
  }
  loadEvent(id:number)
  {
    this.spinner.show();
    this.lookupService.getEvent(id).subscribe((data: any) => {
    this.item=data.results?data.results:data;
    this.eventsImagesPath=environment.apiUrl.substr(0,environment.apiUrl.length-1)+"/uploads/event/";
    this.spinner.hide();
  },response => {
    this.spinner.hide();
    console.error(response);
  });
  }

}
