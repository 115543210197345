export class Eventa {
    id: Number
    body: String
    title: String
    image: String
    dateadded: Date
 constructor()
 {
    this.body=''
    this.title=''
    this.id=0
    this.image=''
    this.dateadded=new Date()
 }   
}
