import { Injectable } from "@angular/core";
import { HttpRequestsService } from 'src/app/shared/services/http-request.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/category.model';
import { Eventa } from "../models/eventa.model";
import { Product } from "../models/product.model";
import { Room } from "../models/room.model";
import { ProductPic } from "../models/productPic.model";

@Injectable()

export class LookupService {

  constructor(private httpService: HttpRequestsService) {}

  
  getCategoriesList(): Observable<Category[]> {
    return this.httpService.getHTTPRequest('api/category')
      .pipe(map((responseData: Category[]) => responseData));
  }

  getMaterialsList(): Observable<Category[]> {
    return this.httpService.getHTTPRequest('api/material')
      .pipe(map((responseData: Category[]) => responseData));
  }

  getEvents(page:number,pagesize:number): Observable<Eventa[]> {
    return this.httpService.getHTTPRequest('api/event?page='+page+'&pagesize='+pagesize)
      .pipe(map((responseData: Eventa[]) => responseData));
  }
  getEvent(id:number): Observable<Eventa> {
    let requestUrl='api/event/'+id;
    return this.httpService.getHTTPRequest(requestUrl)
      .pipe(map((responseData: Eventa) => responseData));
  }
  
  getRandomProducts(): Observable<Product[]> {
    return this.httpService.getHTTPRequest('api/product/random?isactive=1&page=1&pagesize=5&orderfield=id&orderdir=asc&isfeatured=1')
      .pipe(map((responseData: Product[]) => responseData));
  }

  getProducts(categoryid:number,roomid:number): Observable<Product[]> {
    let requestUrl='api/product?isactive=1&page=1&pagesize=100&orderfield=priority&orderdir=desc'+(roomid>0?'&roomid='+roomid:'')+(categoryid>0?'&categoryid='+categoryid:'');
    return this.httpService.getHTTPRequest(requestUrl)
      .pipe(map((responseData: Product[]) => responseData));
  }
  getProductRooms(id:number): Observable<Room[]> {
    let requestUrl='api/product/'+id+'/room';
    return this.httpService.getHTTPRequest(requestUrl)
      .pipe(map((responseData: Room[]) => responseData));
  }
  getProductPics(id:number): Observable<ProductPic[]> {
    let requestUrl='api/product/'+id+'/productpic';
    return this.httpService.getHTTPRequest(requestUrl)
      .pipe(map((responseData: ProductPic[]) => responseData));
  }
  getProduct(id:number): Observable<Product> {
    let requestUrl='api/product/'+id;
    return this.httpService.getHTTPRequest(requestUrl)
      .pipe(map((responseData: Product) => responseData));
  }
  getStylesList(): Observable<Category[]> {
    return this.httpService.getHTTPRequest('api/style')
      .pipe(map((responseData: Category[]) => responseData));
  }
  getDistrictsList(): Observable<Category[]> {
    return this.httpService.getHTTPRequest('api/district')
      .pipe(map((responseData: Category[]) => responseData));
  }
  getRoomsList(): Observable<Room[]> {
    return this.httpService.getHTTPRequest('api/room')
      .pipe(map((responseData: Room[]) => responseData));
  }
  fillForm(email:string)
  {
    return this.httpService.postHTTPRequest('api/contact',email)
    .pipe(map((responseData: any) => responseData));
  }

  sendEmail(email:string)
  {
    return this.httpService.postHTTPRequest('api/mail/contactus',email)
    .pipe(map((responseData: any) => responseData));
  }
}